// website-ui/src/App.js
import React from "react";
import logo from './logo.svg';
import './App.css';

import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Skills } from './components/Skills';
import { Projects } from './components/Projects';
import { FunFacts } from './components/FunFacts';
// import { Contact } from './components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch('/fun-facts')
        .then((res) => res.json())
        .then((data) => {
          setData(data.fact);
          console.log("Category:", data.category);
        })
        .catch((err) => console.error("Error fetching fun facts:", err));
  }, []);


  return (
    <div className="App">
      
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <FunFacts />
      {/* <Contact /> */}
      
    </div>
  );
}

export default App;
