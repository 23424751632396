import { Container, Row, Col } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import reactico from "../assets/skills/react.svg";
import dockerico from "../assets/skills/docker.svg";
import bsico from "../assets/skills/bsico.svg";
import arduinoico from "../assets/skills/arduinoico.svg";
import rpiico from "../assets/skills/rpiico.svg";
import postmanico from "../assets/skills/postmanico.svg";
import vscodeico from "../assets/skills/vscodeico.svg";
import lambdaico from "../assets/skills/lambdaico.svg";
import nodeico from "../assets/skills/nodeico.svg";
import esp32ico from "../assets/skills/esp32ico.svg";

import cppico from "../assets/skills/cppico.svg";
import pythonico from "../assets/skills/pythonico.svg";
import cssico from "../assets/skills/css.svg";
import cico from "../assets/skills/cico.svg";
import jsico from "../assets/skills/jsico.svg";
import javaico from "../assets/skills/javaico.svg";
import htmlico from "../assets/skills/htmlico.svg";


import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 464,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsLeft = {
    ...settings, // Inherit settings from the first slider
    rtl: true, // Reverse the direction
  };

  const techstack = [
    { id: 1, title: "Javascript", icon: jsico },
    { id: 2, title: "Java", icon: javaico },
    { id: 3, title: "C++", icon: cppico },
    { id: 4, title: "C", icon: cico },
    { id: 5, title: "Python", icon: pythonico },
    { id: 6, title: "CSS", icon: cssico },
    { id: 7, title: "HTML", icon: htmlico },
  ];
  const skills = [
    { id: 1, title: "React", icon: reactico },
    { id: 2, title: "Docker", icon: dockerico },
    { id: 3, title: "Burpsuite", icon: bsico },
    { id: 4, title: "RPI", icon: rpiico },
    { id: 5, title: "Arduino", icon: arduinoico },
    { id: 6, title: "Postman", icon: postmanico },
    { id: 7, title: "VSCode", icon: vscodeico },
    { id: 8, title: "AWS Lambda", icon: lambdaico },
    { id: 9, title: "Node JS", icon: nodeico },
    { id: 10, title: "ESP32", icon: esp32ico }
  ];

  return (
    <section className="skill" id="skills">
      <Container>
            <div className="skill-bx">
              <h2>Skills</h2>
              <Slider {...settings}>
                {techstack.map((skill) => (
                  <div key={skill.id}>
                    <img
                      src={skill.icon}
                      alt={skill.title}
                      style={{ width: "100px", margin: "auto" }}
                    />
                    <h4>{skill.title}</h4>
                  </div>
                ))}
              </Slider>
              <Slider {...settingsLeft}>
                {skills.map((skill) => (
                  <div key={skill.id}>
                    <img
                      src={skill.icon}
                      alt={skill.title}
                      style={{ width: "100px", margin: "auto" }}
                    />
                    <h4>{skill.title}</h4>
                  </div>
                ))}
              </Slider>
            </div>
      </Container>
      <img className="background-image-left" src={colorSharp} alt="Background" />
    </section>
  );
};
