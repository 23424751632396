import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import Img from "../assets/img/launch.svg";



export const FunFacts = () => {
  const [fact, setFact] = useState("");
  const [category, setCategory] = useState("");
  const [src, setSource] = useState("");

  useEffect(() => {
    fetch("/fun-facts")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setFact(data.fact || "No fact available");
        setCategory(data.category || "Unknown");
        setSource(data.src || "#");
      })
      .catch((err) => console.error("Error fetching fact:", err));
  }, []);

  const getFontStyle = (category) => {
    switch (category) {
      case "science":
        return { fontFamily: "monospace", color: "green" };
      case "technology":
        return { fontFamily: "monospace", color: "red" };
      case "culture":
        return { fontFamily: "monospace", color: "pink" };
      case "miscellaneous":
        return { fontFamily: "monospace", color: "grey" };
      default:
        return { fontFamily: "sans-serif", color: "black" };
    }
  };






  return (
	<section className="contact" id="connect" style={{ background: 'linear-gradient(to right, #AA367C -5.91%, #4A2FBD 111.58%)' }}>
    <Container className="d-flex justify-content-center align-items-center vh-100" >
            <Row>
                <Col md={12}>
                    <Card className="text-center shadow-lg" style={{ borderRadius: '15px' }}>
                        <Card.Body>
                            <Card.Title className="mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                Bet you didn't know...
                            </Card.Title>
                            <Card.Text className="mb-4" style={{ fontSize: '1.25rem', color: '#555' }}>
                              {fact ? (
                              <a href={src} target="_blank" rel="noopener noreferrer">
                                  <h1>
                                  {fact} <br />
                                  </h1>
                              </a>
                              ) : (
                              <p>Loading a fun fact...</p>
                              )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	</section>
  );
};